export * from "@trpc/react-query/shared";

export const ENDPOINTS = [
  "admin",
  "apiKeys",
  "appRoutingForms",
  "apps",
  "auth",
  "availability",
  "appBasecamp3",
  "bookings",
  "deploymentSetup",
  "eventTypes",
  "features",
  "insights",
  "payments",
  "public",
  "saml",
  "slots",
  "teams",
  "organizations",
  "users",
  "viewer",
  "webhook",
  "workflows",
  "appsRouter",
  "googleWorkspace",
  "oAuth",
] as const;
